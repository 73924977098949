import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Logo from '../images/fivemost-reverse.svg'
const StyledFooter = styled.footer`
  background: #6c6c6c;
  min-height: 200px;
  text-align: center;
  padding: 15px;
  color: #eeeeee;
  font-size: 14px;

  img {
    width: 150px;
    margin: 0 auto;
  }

  p {
    margin: 5px 0;
  }
`

const Footer = () => (
  <StyledFooter>
    <Link to="/">
      <img src={Logo} alt="Fivemost" />
    </Link>
    <p>
      Fivemost is where you will find new and interesting products from the
      internet. Fivemost may contain links to Amazon products that may receive
      compensation at no additional cost to you.
    </p>
    <small>
      {new Date().getFullYear()}
      {` `}
      <a href="https://www.fivemost.net">Fivemost</a>. All Rights Reserved
    </small>
  </StyledFooter>
)

export default Footer
